import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { StorageService } from "../store/storage.service";
import { SessionService } from "../services/session.service";

@Injectable({
  providedIn: "root",
})
export class AlwaysAuthGuard {
  constructor(
    private storage: StorageService,
    private router: Router,
    private sessionService: SessionService
  ) {}
  canActivate() {
    let data = this.storage?.getUsersData;
    let token = null;
    let redirectionLink = window?.location?.href?.replace(
      window?.location?.origin,
      ""
    );
    if (data !== null) {
      token = data?.token;
    }
    if (token === undefined || token === null || token === "") {
      this.storage?.clearStorage();
      this.storage?.setIsDirectPageLink("true");
      this.storage?.setRedirectPageLink(redirectionLink);
      this.router.navigate(["users/sign_in"]);
      this.sessionService.isSessionActive$.next(false);
      return false;
    } else {
      this.storage?.setIsDirectPageLink("true");
      this.sessionService.isSessionActive$.next(true);
      this.storage?.setRedirectPageLink(redirectionLink);
      return true;
    }
  }
}
